.loading{
    width: 100vw;
    height: 100vh;
    /* background-color: black; */
    display: grid;
    place-items: center;
    max-width: 100%

}

.loading>div{
    width: 7vmax ;
     background-color: white;
     height: 7vmax;
     border-bottom: 5px solid #0285AD;
     border-radius: 50%;
     animation: loadingRotating 1500ms linear infinite ;
}


@keyframes loadingRotating {
    0% {
      transform: rotateZ(0deg);
    }
    50% {
      transform: rotateZ(-360deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  } 