.html-string-overview img {
  width: 90%;
  margin: auto;
  object-position: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.Product-Page td {
  border: 1px solid !important;
  /* border-color: #dadada !important; */
  padding-top: 12px !important;
  padding-left: 10px !important;
  width: 50% !important;
  max-width: 50px !important;

  /* background-color: blue !important; */

  word-wrap: break-word !important; /* Ensures long words wrap to the next line */
}

.product-page {
  display: flex !important;
  max-width: 100% !important;
}

.product-page * {
  max-width: 50% !important;
  box-sizing: border-box !important;
}
